import { useState, useEffect, useCallback } from 'react';
import { useWeb3 } from '../hooks/Web3Context';
import { Card, Grid, Typography, CircularProgress } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import EventEmitter from 'events';
import Web3 from 'web3';

// Augmenter la limite des écouteurs, si nécessaire
EventEmitter.defaultMaxListeners = 200;

const fetchWithRetry = async (url: string, options: any = {}, retries: number = 3, delay: number = 1000): Promise<any> => {
    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        if (retries > 0) {
            console.warn(`Retrying... (${retries} retries left)`);
            await new Promise(res => setTimeout(res, delay));
            return fetchWithRetry(url, options, retries - 1, delay * 2);
        } else {
            throw error;
        }
    }
};

export default function AvatarContent() {
    const [tokens, setTokens] = useState<{ tokenId: number, tokenURI: string }[]>([]);
    const [loading, setLoading] = useState(true);
    const [tokenData, setTokenData] = useState<any>(null);
    const [favoriteToken, setFavoriteToken] = useState<any>(null);

    const { getTokensURI, userAddress } = useWeb3();

    console.log("TOKENS: ", tokens);

    const setContent = useCallback(async () => {
        try {
            const tokens = await getTokensURI(userAddress);
            if (tokens && Array.isArray(tokens)) {
                setTokens(tokens);
                const tokenDataPromises = tokens.map(async (token) => {
                    if (token.tokenURI) {  // Ensure tokenURI exists
                        const ipfsUrl = token.tokenURI;
                        const data = await fetchWithRetry(ipfsUrl);
                        return { tokenId: token.tokenId, ...data };
                    } else {
                        console.warn(`Token ${token.tokenId} is missing tokenURI`);
                        return null;  // Return null or handle as needed
                    }
                });
                const data = (await Promise.all(tokenDataPromises)).filter((d) => d !== null);
                const lastToken = data.length > 0 ? data[data.length - 1] : null;
                setTokenData(lastToken);
    
                // Récupérer l'ID du token favori depuis le localStorage
                const favoriteTokenId = localStorage.getItem('favoriteTokenId');
                if (favoriteTokenId) {
                    const favoriteToken = data.find(token => token?.tokenId === parseInt(favoriteTokenId));
                    setFavoriteToken(favoriteToken);
                }
            } else {
                setTokens([]);
            }
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch tokens URI:', error);
            setTokens([]);
            setLoading(false);
        }
    }, [getTokensURI, userAddress]);
    

    useEffect(() => {
        const web3 = new Web3((window as any).ethereum);

        setContent();

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                setContent();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);

            if (web3 && web3.currentProvider) {
                web3.currentProvider.removeListener('newListener', handleVisibilityChange);
            }
        };
    }, [setContent]);

    if (loading) {
        return <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}><CircularProgress sx={{ color: '#fb553c' }} /></Grid>;
    }

    const displayTokenData = favoriteToken || tokenData;
    if (tokens.length > 0 && displayTokenData) {
        return (
            <Grid >
                <Card sx={{ backgroundColor: 'transparent', border: '1px solid white' }}>
                    <Typography variant="h4" sx={{ marginBottom: 2, pt: 1, textAlign: 'center' }}>{displayTokenData.name}</Typography>
                </Card>
                <Card id="avatarImg" sx={{ width: '100%' }}>
                    <img src={displayTokenData.image} alt="Avatar" />
                </Card>
            </Grid>
        );
    }

    return (
        <Grid>
            <PersonIcon sx={{ width: '100%', height: '1%', color: '#fb553C' }} />
            <Typography variant="h6" sx={{ color: 'white', textAlign: 'center', width: '100%' }}>
                No NFT available, please mint your NFT in Manage Avatar window.
            </Typography>
        </Grid>
    );
}
