import { useState } from "react";
import { Grid, Typography, TextField, CircularProgress, Box } from "@mui/material";
import { useWeb3 } from "../hooks/Web3Context";
import imageCompression from 'browser-image-compression';
import bgCard from '../images/bgCard.png';


const pinataApiKey = '5133398d34056dfb1fac';



interface AvatarUploadContentProps {
    onMintSuccess: (metadata: any, hash: string) => void;
}

export default function AvatarUploadContent({ onMintSuccess }: AvatarUploadContentProps) {
    // Initialisation des données
    const [file, setFile] = useState<File | null>(null);

    const [name, setName] = useState('');
    const [metadataHash, setMetadataHash] = useState(null);
    const [metadata, setMetadata] = useState<object | null>(null);
    const [uploading, setUploading] = useState(false);
    const [minting, setMinting] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const { userAddress, mintTokens, getTransactionHash } = useWeb3(); // Récupération de l'adresse de l'utilisateur, de la fonction mintTokens, de la fonction getTransactionHash et de la fonction tokenURI

    // Récupération de l'image

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        setFile(event.target.files[0]);
      }
    };
    
      
      

    // Récupération du nom
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    // Fonction pour épingler le fichier sur IPFS
    const handleUpload = async () => {
        if (!file) {
            alert("Please upload an avatar image.");
            return;
        }

        // Limite la taille du fichier à 5MB et la largeur à 5000px
        const options = {
            maxSizeMB: 5,
            maxWidthOrHeight: 5000,
            useWebWorker: true
        };

        try {
            setUploading(true);
            const compressedFile = await imageCompression(file, options);
            const validMimeTypes = ['image/jpeg', 'image/png', 'image/gif'];

            if (!validMimeTypes.includes(compressedFile.type)) {
                alert("Invalid file type. Please upload a JPEG, PNG, or GIF image.");
                setUploading(false);
                return;
            }

            if (!name.trim()) {
                alert("Please enter a name.");
                setUploading(false);
                return;
            }

            await pinFileToIPFS(compressedFile, name);
            setUploading(false);
        } catch (error) {
            alert("Error processing file. Please try again.");
            console.error(error);
            setUploading(false);
        }
    };

    // Fonction pour mint l'avatar
    const handleMint = async () => {
        if (!metadataHash) {
            alert("Please upload the avatar before minting.");
            return;
        }
        setMinting(true);
        await mintNFT(metadataHash, metadata || {});
        setMinting(false);
    };

    // Fonction pour épingler le fichier sur IPFS
    const pinFileToIPFS = async (file: File, userName: string) => {
        try {
            console.log('Fetching Pinata secret key...', );
            console.log("Nom de l'image chargé :", file.name);
            //const secretKeyResponse = await fetch('/api/getPinataSecret');
            console.log("Name chargé :", userName);
            //const secretKeyData = await secretKeyResponse.json();
            const pinataSecretApiKey = "52d8381331acbbc58482ccf2016ede2ffb61f353e1a9a90c90ac160e79ccab37";

            console.log('Pinata secret key fetched successfully.');

            const formData = new FormData();
            formData.append('file', file);

            console.log('Uploading file to IPFS...');
            const response = await fetch('https://api.pinata.cloud/pinning/pinFileToIPFS', {
                method: 'POST',
                headers: {
                    'pinata_api_key': pinataApiKey,
                    'pinata_secret_api_key': pinataSecretApiKey,
                },
                body: formData
            });

            const data = await response.json();
            const ipfsHash = data.IpfsHash;
            console.log('File uploaded successfully to IPFS:', ipfsHash);

            // Creation des meta données en JSON
            const metadata = {
                name: userName,
                description: 'Complementary profiles of passionate people, covering all the needs of the project between creative people, Web3 experts and strategic managers having a business and functional expertise and an established network in the Web3.',
                image: `https://ipfs.io/ipfs/${ipfsHash}`,
                attributes: [
                    {
                        trait_type: "Year",
                        value: "2024"
                    },
                    {
                        trait_type: "Edition",
                        value: "V2"
                    }
                ]
            };

            // Epingler les meta données sur IPFS
            await pinJSONToIPFS(metadata);
        } catch (error) {
            console.error('Error uploading to IPFS:', error);
        }
    };

    // Fonction pour épingler les meta données sur IPFS
    const pinJSONToIPFS = async (metadata: object) => {
        try {
            //const secretKeyResponse = await fetch('/api/getPinataSecret');
            //const secretKeyData = await secretKeyResponse.json();
            const pinataSecretApiKey = "52d8381331acbbc58482ccf2016ede2ffb61f353e1a9a90c90ac160e79ccab37"
  

            const response = await fetch('https://api.pinata.cloud/pinning/pinJSONToIPFS', {
                method: 'POST',
                headers: {
                    'pinata_api_key': pinataApiKey,
                    'pinata_secret_api_key': pinataSecretApiKey,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(metadata)
            });

            const data = await response.json();
            const metadataHash = data.IpfsHash;
            setMetadataHash(metadataHash);
            setMetadata(metadata || {});
            console.log('Metadata pinned successfully to IPFS:', metadataHash);
            alert("The data has been uploaded. You can now mint your NFT.");
        } catch (error) {
            console.error('Error pinning JSON to IPFS:', error);
        }
    };

    // Fonction pour mint l'avatar
    const mintNFT = async (metadataHash: string, metadata: object) => {
        console.log("user address :", userAddress);
        try {
            console.log('Minting NFT...');
            await mintTokens(userAddress, metadataHash);
            console.log('NFT minted successfully:');
            const hash = await getTransactionHash();
            console.log('Hash Transaction:', hash);
            if (hash != null) {
                onMintSuccess(metadata, hash);
            }
            console.log('NFT minted successfully.');
        } catch (error) {
            console.error('Error minting NFT:', error);
        }
    };

    return (
        <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
            {/* Grid Create Avatar */}
            <Grid item xs={12} sm={12} md={12} lg={12} textAlign={'center'} sx={{
                width: '100%',
                borderRadius: 5,
                overflow: 'hidden',
                position: 'relative',
                backgroundColor: 'transparent',
                paddingTop: 5,
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${bgCard})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 0.5,
                    zIndex: 0,
                },
            }}>
                <Box sx={{ position: 'relative', zIndex: 1, width: '100%', padding: 5 }}>
                    <Typography variant="h4" sx={{ marginBottom: 2, border: 'solid 1px #fb553C', borderRadius: 2 }}>Build</Typography>
                    <a href="https://readyplayer.me/" target="_blank" rel="noreferrer" style={{ backgroundColor: '#FB553C', color: 'white', padding: 5, borderRadius: 10, fontWeight: 'bold' }}>Click here to build your avatar</a>
                </Box>
            </Grid>


            {/* Grid Upload Avatar */}
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{
                marginTop: 5,
                width: '100%',
                borderRadius: 5,
                overflow: 'hidden',
                position: 'relative',
                backgroundColor: 'transparent',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${bgCard})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 0.5,
                    zIndex: 0,
                },
            }}>
                <Box sx={{ position: 'relative', zIndex: 1, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 3 }}>
                    <Typography variant="h4" sx={{ border: 'solid 1px #fb553c', borderRadius: 2, padding: 1 }}>Mint</Typography>
                    <button id="avatarImg" style={{ margin: 10, backgroundColor: '#fb553c', padding: 10, paddingLeft: 20, paddingRight: 20, borderRadius: 5, color: 'white', fontWeight: 'bold' }}>
                        <input type="file" accept=".png, .jpg, .jpeg, .gif" onChange={handleFileChange} />
                    </button>
                    <TextField id="avatarName" label="Enter your name" value={name} onChange={handleNameChange} sx={{ border: 'solid 1px white', borderRadius: 2 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, marginTop: 2 }}>
                        {uploading ? (
                            <CircularProgress sx={{ marginTop: 2, color: '#fb553c' }} />
                        ) : (
                            <button
                                id="uploadButton"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                style={{ margin: 2, marginLeft: 10, backgroundColor: isHovered ? '#d94d30' : '#fb553c', cursor: 'pointer', padding: 10, paddingLeft: 20, paddingRight: 20, borderRadius: 5, color: 'white', fontWeight: 'bold' }}
                                onClick={handleUpload}
                                disabled={!file || !name.trim()}
                            >
                                Upload Avatar
                            </button>
                        )}
                        {minting ? (
                            <CircularProgress sx={{ marginTop: 2, color: '#fb553c' }} />
                        ) : (
                            <button
                                id="mintButton"
                                onMouseEnter={() => setIsHovered2(true)}
                                onMouseLeave={() => setIsHovered2(false)}
                                style={{ margin: 2, marginLeft: 10, backgroundColor: isHovered2 ? '#d94d30' : '#fb553c', cursor: 'pointer', padding: 10, paddingLeft: 20, paddingRight: 20, borderRadius: 5, color: 'white', fontWeight: 'bold' }}
                                onClick={handleMint}
                                disabled={!metadataHash}
                            >
                                Mint Avatar
                            </button>
                        )}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}
