import { useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, TextField, Button, Divider, Avatar } from '@mui/material';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import { useWeb3 } from '../hooks/Web3Context';
import '../components/ui/dashboarstyle.css';
import bgCard from '../images/bgCard.png';

export default function StakingContent() {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredClaim, setIsHoveredClaim] = useState(false);
  const { stackReward, stackTokens, claimStackedTokens, stackedTokens, remainingStackingTime } = useWeb3();

  const [stackAmount, setStackAmount] = useState('');

  const handleStack = async () => {
    if (!stackAmount) return;
    const amountNum = parseFloat(stackAmount);
    if (isNaN(amountNum) || amountNum <= 0) {
      alert('Please enter a valid number to stack.');
      return;
    }
    await stackTokens(amountNum);
    setStackAmount('');
  };

  const handleClaim = async () => {
    await claimStackedTokens();
  };

  const formatTime = (timeInSeconds: number) => {
    const days = Math.floor(timeInSeconds / (3600 * 24));
    const hours = Math.floor(timeInSeconds % (3600 * 24) / 3600);
    const minutes = Math.floor(timeInSeconds % 3600 / 60);
    const seconds = Math.floor(timeInSeconds % 60);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <Grid container spacing={2} justifyContent="center" sx={{
      color: '#ffffff',
      backgroundColor: 'transparent',
      '.MuiCard-root': { backgroundColor: 'transparent', color: '#ffffff' },
      '.MuiTextField-root': {
        '& input': { color: '#ffffff' },
        '& label': { color: '#ffffff' }
      }
    }}>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Card sx={{ border: '1px solid #fb553c' }}>
          <Typography variant="h6" sx={{ textAlign: 'center', my: 2 }}>Stack Your Tokens</Typography>
        </Card>
      </Grid>

      <Grid container spacing={2} justifyContent="center" sx={{
        color: '#ffffff',
        backgroundColor: 'transparent',
        '.MuiCard-root': { backgroundColor: 'transparent', color: '#ffffff' },
        '.MuiTextField-root': {
          '& input': { color: '#ffffff' },
          '& label': { color: '#ffffff' }
        }
      }}>
        {stackedTokens === 0 ? (
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{
            position: 'relative',
            borderRadius: 5,
            overflow: 'hidden', // Assure que le pseudo-élément ne déborde pas
          }}>
            <Box sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              borderRadius: 5, // Ajout du borderRadius au conteneur
              overflow: 'hidden', // Assure que le pseudo-élément ne déborde pas
              '&::before': {
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundImage: `url(${bgCard})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                opacity: 0.5,
                zIndex: 0, // Assure que le pseudo-élément reste derrière le contenu
                borderRadius: 5, // Ajout du borderRadius au pseudo-élément
              }
            }}>
              <Box sx={{ position: 'relative', zIndex: 1, width: '100%' }}>
                <Card raised>
                  <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ bgcolor: '#fb553c', mr: 1 }}><ControlPointDuplicateIcon /></Avatar>
                    <TextField label="Stack Amount" type="number" value={stackAmount} onChange={(e) => setStackAmount(e.target.value)} fullWidth />
                    <button
                      onClick={handleStack}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      style={{
                        margin: 2,
                        marginLeft: 10,
                        backgroundColor: isHovered ? '#c93d20' : '#fb553c',
                        padding: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                        borderRadius: 5,
                        color: 'white',
                        fontWeight: 'bold'
                      }}
                    >STACK
                    </button>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{
            position: 'relative',
            borderRadius: 5,
            overflow: 'hidden', // Assure que le pseudo-élément ne déborde pas
          }}>
            <Box sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              borderRadius: 5, // Ajout du borderRadius au conteneur
              overflow: 'hidden', // Assure que le pseudo-élément ne déborde pas
              '&::before': {
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundImage: `url(${bgCard})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                opacity: 0.5,
                zIndex: 0, // Assure que le pseudo-élément reste derrière le contenu
                borderRadius: 5, // Ajout du borderRadius au pseudo-élément
              }
            }}>
              <Box sx={{ position: 'relative', zIndex: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Card sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <Typography variant="h6" gutterBottom>
                    Staked Tokens: {stackedTokens}
                  </Typography>
                  <Typography variant="body1">
                    Time remaining: {formatTime(remainingStackingTime)}
                  </Typography>
                  <button
                    onClick={handleClaim}
                    disabled={remainingStackingTime > 0}
                    onMouseEnter={() => setIsHoveredClaim(true)}
                    onMouseLeave={() => setIsHoveredClaim(false)}
                    style={{
                      margin: 2,
                      marginLeft: 10,
                      backgroundColor: isHoveredClaim ? '#c93d20' : '#fb553c',
                      padding: 10,
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 5,
                      color: 'white'
                    }}
                  >
                    Claim Rewards
                  </button>

                </Card>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>

  );
}
