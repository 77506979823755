import React, { useState } from 'react';
import { Box, Grid, Paper, List, ListItem, Card, CardHeader, CardContent, IconButton } from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import bgHome from '../images/bgHome.png';
import bgCard from '../images/bgCard.png';

interface FAQItem {
    id: number;
    title: string;
    content: string;
}

const faqItems: FAQItem[] = [
    { id: 1, title: 'What is the role of $Doors3 tokens within the company?', content: '$Doors3 tokens have several functions within the company. They act as a tool for employee compensation, encouraging employee engagement and retention. Furthermore, they can also be used to purchase goods and services from the company\'s exclusive marketplace. Finally, they will eventually allow for participation in company governance through a DAO system.' },
    { id: 2, title: 'What is the DAO system and how does it work?', content: 'The DAO system is a decentralized autonomous organization that allows token holders to participate in the governance of the company. This system is built on blockchain technology and enables transparent and democratic decision-making processes.' },
    { id: 3, title: 'How is the exchange rate between $Doors3 tokens and MATIC determined?', content: 'The exchange rate between $Doors3 tokens and MATIC is determined by the administrator and is based on the performance of the company. This means that the better the company performs, the higher the value of the tokens.' },
    { id: 4, title: 'What benefits do I get from staking my tokens?', content: 'When you stake your tokens, you lock them for a defined period. At the end of this period, you can get your tokens back with a bonus percentage. This encourages saving and long-term holding of the tokens.' },
    { id: 5, title: 'How do I become an authorized user?', content: 'The process of becoming an authorized user is handled by the company\'s administrators. If you\'re an employee of the company, this should be done as part of your onboarding process. If not, you may need to contact the company for further information.' },
    { id: 6, title: 'What can I do with my tokens if I\'m no longer an authorized user?', content: 'If you\'re no longer an authorized user, you can still withdraw any tokens that you have staked. However, you won\'t be able to use your tokens to vote in the DAO, purchase items from the marketplace, or exchange your tokens for MATIC.' },
    { id: 7, title: 'What happens to my tokens if I leave the company?', content: 'If you leave the company, you can still withdraw any tokens that you have staked. However, you may lose your authorization status and therefore lose the ability to use your tokens for voting, purchasing items, or exchanging for MATIC.' },
    { id: 8, title: 'What can I purchase in the exclusive marketplace?', content: 'The exclusive marketplace will offer various goods and services that can be purchased using $Doors3 tokens. The specifics of what will be available are yet to be announced.' },
    { id: 9, title: 'What is the roadmap for $Doors3 tokens?', content: 'The roadmap for $Doors3 includes the development of a DAO Voting System, the creation of an exclusive marketplace for token holders, and several other exciting developments. More details will be announced as these developments progress.' },
    { id: 10, title: 'What are the security measures in place for $Doors3 tokens?', content: '$Doors3 tokens have been developed with security as a primary concern. The smart contract for the tokens has strict access controls, and only authorized users are able to use the various utilities of the tokens. Additionally, the contract uses OpenZeppelin, a library known for its secure and tested smart contracts. The smart contract has been audited by a professional.' }
];

export default function FAQContent() {
    const [expandedItems, setExpandedItems] = useState<number[]>([]);

    const handleToggle = (id: number) => {
        setExpandedItems((prevExpandedItems) =>
            prevExpandedItems.includes(id)
                ? prevExpandedItems.filter((itemId) => itemId !== id)
                : [...prevExpandedItems, id]
        );
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundImage: `url(${bgHome})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                paddingTop: 4,
                boxSizing: 'border-box',
            }}
        >
            <Box
                sx={{
                    width: '90%',
                    maxHeight: '90%',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: 2,
                    boxSizing: 'border-box',
                    my: "4%",
                    '&::-webkit-scrollbar': {
                        width: '12px',
                    },
                    scrollbarColor: '#FB553C transparent',
                    scrollbarWidth: 'thin'
                }}
            >
                <Paper
                    sx={{
                        width: '100%',
                        borderRadius: 5,
                        overflow: 'hidden',
                        position: 'relative',
                        backgroundColor: 'transparent',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            backgroundImage: `url(${bgCard})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            opacity: 0.5,
                            zIndex: 0,
                        },
                    }}
                >
                    <Box sx={{ position: 'relative', zIndex: 1, width: '100%' }}>
                        <List>
                            {faqItems.map((item) => (
                                <ListItem key={item.id} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Card sx={{ backgroundColor: '#424242', color: '#ffffff', width: '100%' }}>
                                        <CardHeader
                                            title={item.title}
                                            titleTypographyProps={{ variant: 'h6', sx: { color: '#ffffff' }, fontSize: '1rem' }}
                                            action={
                                                <IconButton onClick={() => handleToggle(item.id)} sx={{ color: '#ffffff' }}>
                                                    {expandedItems.includes(item.id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                            }
                                        />
                                        {expandedItems.includes(item.id) && (
                                            <CardContent sx={{ color: '#ffffff' }}>
                                                {item.content}
                                            </CardContent>
                                        )}
                                    </Card>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
}
