import { useState } from 'react';
import { Box, Grid, Card, CardHeader, CardContent, TextField, Typography, Avatar } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import ShareIcon from '@mui/icons-material/Share';
import { useWeb3 } from '../hooks/Web3Context';
import StackingContent from './StackingContent';
import bgManger from '../images/bgManageD3.png';
import bgCard from '../images/bgCard.png';

export default function SellBuyContent() {
  const { userTokenBalance, conversionRate, availableTokens, buy, sellTokens, userMATICShare } = useWeb3();
  const [buyAmount, setBuyAmount] = useState('');
  const [sellAmount, setSellAmount] = useState('');
  const [isHoveredBuy, setIsHoveredBuy] = useState(false);
  const [isHoveredSell, setIsHoveredSell] = useState(false);
  const tokenValue = conversionRate ? (100 / conversionRate).toFixed(2) : 'Loading...';

  const handleBuy = async () => {
    if (!buyAmount) return;
    const amountNum = parseFloat(buyAmount);
    if (isNaN(amountNum) || amountNum <= 0) {
      alert('Please enter a valid number to buy.');
      return;
    }
    await buy(amountNum);
    setBuyAmount('');
  };

  const handleSell = async () => {
    if (!sellAmount) return;
    const amountNum = parseFloat(sellAmount);
    if (isNaN(amountNum) || amountNum <= 0) {
      alert('Please enter a valid number to sell.');
      return;
    }
    await sellTokens(amountNum);
    setSellAmount('');
  };

  return (
    <Box sx={{
      position: 'relative',
      minHeight: '100vh',
      width: '100vw',
      overflow: 'hidden'
    }}>
      {/* Fixed background */}
      <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${bgManger})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        zIndex: 0
      }} />

      {/* Scrollable content */}
      <Box sx={{
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        overflowY: 'auto',
        pt: '80px',
        zIndex: 1,
        backgroundColor: 'transparent'
      }}>
        <Grid container spacing={3} sx={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 3,
          px: 3,
          color: '#ffffff',
          backgroundColor: '#transparent'
        }}>
          {/* Grid item for the left content -|- will be change with the import of a new file object for the stack progress*/}
          <Grid item xs={12} sm={12} md={2} lg={4}>
            {/*
          <h1 style={{ color: '#ffffff' }}>Stacking Portal</h1>
          <img src="/DOORS3_LOGO.png" alt="DOORS3" style={{ width: '100%', height: 'auto' }} />
          <Typography variant="subtitle1" align="center">
            Get *10%* rewards by staking your tokens
          </Typography>
          */}
          </Grid>
          {/* Grid item for the right contents */}
          <Grid item xs={12} sm={12} md={10} lg={8} sx={{ display: 'flex', flexDirection: 'column' }}>
            {/* Grid Container for the wallet information */}
            <Grid container spacing={2} sx={{ mb: 6, display: 'flex', justifyContent: 'center' }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card sx={{ bgcolor: 'transparent', border: 'solid 1px #fb553c' }}>
                  <Typography variant="h6" sx={{ textAlign: 'center', my: 2, color: 'white' }}>Wallet Informations</Typography>
                </Card>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 0.5 }}>
                {/* User Balance Card */}
                <Grid item xs={12} sm={6} md={3} lg={3} sx={{
                  position: 'relative',
                  borderRadius: 5,
                  overflow: 'hidden', // Assure que le pseudo-élément ne déborde pas
                }}>
                  <Box sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    borderRadius: 5, // Ajout du borderRadius au conteneur
                    overflow: 'hidden', // Assure que le pseudo-élément ne déborde pas
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${bgCard})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      opacity: 0.5,
                      zIndex: 0, // Assure que le pseudo-élément reste derrière le contenu
                      borderRadius: 5, // Ajout du borderRadius au pseudo-élément
                    }
                  }}>
                    <Box sx={{ position: 'relative', zIndex: 1, width: '100%' }}>
                      <Card sx={{ bgcolor: 'transparent' }}>
                        <CardHeader avatar={<Avatar sx={{ bgcolor: '#fb553c' }}><AccountBalanceWalletIcon /></Avatar>} sx={{ color: 'white' }} title="Balance" />
                        <CardContent>
                          <Typography variant="body1" sx={{ color: 'white' }}>{userTokenBalance} D3</Typography>
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                </Grid>

                {/* Token Value Card */}
                <Grid item xs={12} sm={6} md={3} lg={3} sx={{
                  position: 'relative',
                  borderRadius: 5,
                  overflow: 'hidden', // Assure que le pseudo-élément ne déborde pas
                }}>
                  <Box sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    borderRadius: 5, // Ajout du borderRadius au conteneur
                    overflow: 'hidden', // Assure que le pseudo-élément ne déborde pas
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${bgCard})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      opacity: 0.5,
                      zIndex: 0, // Assure que le pseudo-élément reste derrière le contenu
                      borderRadius: 5, // Ajout du borderRadius au pseudo-élément
                    }
                  }}>
                    <Box sx={{ position: 'relative', zIndex: 1, width: '100%' }}>
                      <Card sx={{ bgcolor: 'transparent' }}>
                        <CardHeader avatar={<Avatar sx={{ bgcolor: '#fb553c' }}><MonetizationOnIcon /></Avatar>} sx={{ color: 'white' }} title="Token Value" />
                        <CardContent>
                          <Typography variant="body1" sx={{ color: 'white' }}>1 D3 = {tokenValue} MATIC</Typography>
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                </Grid>

                {/* Available Token Card */}
                <Grid item xs={12} sm={6} md={3} lg={3} sx={{
                  position: 'relative',
                  borderRadius: 5,
                  overflow: 'hidden', // Assure que le pseudo-élément ne déborde pas
                }}>
                  <Box sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    borderRadius: 5, // Ajout du borderRadius au conteneur
                    overflow: 'hidden', // Assure que le pseudo-élément ne déborde pas
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${bgCard})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      opacity: 0.5,
                      zIndex: 0, // Assure que le pseudo-élément reste derrière le contenu
                      borderRadius: 5, // Ajout du borderRadius au pseudo-élément
                    }
                  }}>
                    <Box sx={{ position: 'relative', zIndex: 1, width: '100%' }}>
                      <Card sx={{ bgcolor: 'transparent' }}>
                        <CardHeader avatar={<Avatar sx={{ bgcolor: '#fb553c' }}><LocalGroceryStoreIcon /></Avatar>} sx={{ color: 'white' }} title="Available Tokens" />
                        <CardContent>
                          <Typography variant="body1" sx={{ color: 'white' }}>{availableTokens} D3</Typography>
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                </Grid>

                {/* MATIC Share Card */}
                <Grid item xs={12} sm={6} md={3} lg={3} sx={{
                  position: 'relative',
                  borderRadius: 5,
                  overflow: 'hidden', // Assure que le pseudo-élément ne déborde pas
                }}>
                  <Box sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    borderRadius: 5, // Ajout du borderRadius au conteneur
                    overflow: 'hidden', // Assure que le pseudo-élément ne déborde pas
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${bgCard})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      opacity: 0.5,
                      zIndex: 0, // Assure que le pseudo-élément reste derrière le contenu
                      borderRadius: 5, // Ajout du borderRadius au pseudo-élément
                    }
                  }}>
                    <Box sx={{ position: 'relative', zIndex: 1, width: '100%' }}>
                      <Card sx={{ bgcolor: 'transparent' }}>
                        <CardHeader avatar={<Avatar sx={{ bgcolor: '#fb553c' }}><ShareIcon /></Avatar>} sx={{ color: 'white' }} title="MATIC Share" />
                        <CardContent>
                          <Typography variant="body1" sx={{ color: 'white' }}>{userMATICShare}</Typography>
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                </Grid>

              </Grid>
              {/* Grid Container for the buy and sell forms */}
              <Grid container spacing={2} sx={{ mb: 6, display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Grid item xs={12}>
                  <Card sx={{ bgcolor: 'transparent', border: 'solid 1px #fb553c' }}>
                    <Typography variant="h6" sx={{ textAlign: 'center', my: 2, color: 'white' }}>Buy/Sell Tokens</Typography>
                  </Card>
                </Grid>
                {/* Buy Card */}
                <Grid item xs={12} sm={12} md={6} lg={6} sx={{
                  position: 'relative',
                  borderRadius: 5,
                  overflow: 'hidden', // Assure que le pseudo-élément ne déborde pas
                }}>
                  <Box sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    borderRadius: 5, // Ajout du borderRadius au conteneur
                    overflow: 'hidden', // Assure que le pseudo-élément ne déborde pas
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${bgCard})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      opacity: 0.5,
                      zIndex: 0, // Assure que le pseudo-élément reste derrière le contenu
                      borderRadius: 5, // Ajout du borderRadius au pseudo-élément
                    }
                  }}>
                    <Box sx={{ position: 'relative', zIndex: 1, width: '100%' }}>
                      <Card sx={{ bgcolor: 'transparent' }}>
                        <CardContent sx={{ display: 'flex', justifyContent: 'row', alignItems: 'center' }}>
                          <Avatar sx={{ bgcolor: '#fb553c', mr: 1 }}><ArrowCircleDownIcon /></Avatar>
                          <TextField label="Buy Amount" value={buyAmount} onChange={(e) => setBuyAmount(e.target.value)} fullWidth />
                          <button
                            onClick={handleBuy}
                            onMouseEnter={() => setIsHoveredBuy(true)}
                            onMouseLeave={() => setIsHoveredBuy(false)}
                            style={{
                              margin: 2,
                              marginLeft: 10,
                              backgroundColor: isHoveredBuy ? '#c93d20' : '#fb553c',
                              padding: 10,
                              paddingLeft: 20,
                              paddingRight: 20,
                              borderRadius: 5,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >BUY
                          </button>
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                </Grid>
                {/* Sell Card */}
                <Grid item xs={12} sm={12} md={6} lg={6} sx={{
                  position: 'relative',
                  borderRadius: 5,
                  overflow: 'hidden', // Assure que le pseudo-élément ne déborde pas
                }}>
                  <Box sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    borderRadius: 5, // Ajout du borderRadius au conteneur
                    overflow: 'hidden', // Assure que le pseudo-élément ne déborde pas
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${bgCard})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      opacity: 0.5,
                      zIndex: 0, // Assure que le pseudo-élément reste derrière le contenu
                      borderRadius: 5, // Ajout du borderRadius au pseudo-élément
                    }
                  }}>
                    <Box sx={{ position: 'relative', zIndex: 1, width: '100%' }}>
                      <Card sx={{ bgcolor: 'transparent' }}>
                        <CardContent sx={{ display: 'flex', justifyContent: 'row', alignItems: 'center' }}>
                          <Avatar sx={{ bgcolor: '#fb553c', mr: 1 }}><ArrowCircleUpIcon /></Avatar>
                          <TextField label="Sell Amount" value={sellAmount} onChange={(e) => setSellAmount(e.target.value)} fullWidth />
                          <button
                            onClick={handleSell}
                            onMouseEnter={() => setIsHoveredSell(true)}
                            onMouseLeave={() => setIsHoveredSell(false)}
                            style={{
                              margin: 2,
                              marginLeft: 10,
                              backgroundColor: isHoveredSell ? '#c93d20' : '#fb553c',
                              padding: 10,
                              paddingLeft: 20,
                              paddingRight: 20,
                              borderRadius: 5,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >SELL
                          </button>
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              {/* Grid Container for the stacking */}
              <StackingContent />
            </Grid>
          </Grid>
        </Grid>
      </Box >
    </Box>
  );
}
