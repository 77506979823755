import { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Box, Grid } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/AdminPanelSettings';
import StakingIcon from '@mui/icons-material/CurrencyExchange';
import LitePaperIcon from '@mui/icons-material/Description';
import FAQIcon from '@mui/icons-material/QuestionMark';
import ManageAvatarIcon from '@mui/icons-material/ManageAccounts';
import HomeContent from './HomeContent';
import SellBuyContent from './SellBuyContent';
import LitePaperContent from './LitePaperContent';
import AdminContent from './AdminContent';
import FAQContent from './FAQContent';
import ManageAvatarContent from './ManageAvatarContent';
import { useWeb3 } from '../hooks/Web3Context';
import EventEmitter from 'events';
import doorsLabLogo from '../images/doorslablogo.png';
import '../tailwind.css';




EventEmitter.defaultMaxListeners = 200;

export default function Dashboard() {
  const { isAdmin } = useWeb3();
  const [activeSection, setActiveSection] = useState('Home');

  const [menuItems, setMenuItems] = useState([
    { text: 'Home', icon: <HomeIcon className="mr-2" />, content: <HomeContent /> },
    { text: 'Manage D3', icon: <StakingIcon className="mr-2" />, content: <SellBuyContent /> },
    { text: 'Lite Paper', icon: <LitePaperIcon className="mr-2" />, content: <LitePaperContent /> },
    { text: 'FAQ', icon: <FAQIcon className="mr-2" />, content: <FAQContent /> },
    { text: 'Manage Avatar', icon: <ManageAvatarIcon className="mr-2" />, content: <ManageAvatarContent /> },
  ]);

  useEffect(() => {
    if (isAdmin) {
      setMenuItems((currentItems) => [
        ...currentItems,
        { text: 'Admin', icon: <SettingsIcon className="mr-2" />, content: <AdminContent /> },
      ]);
    }
  }, [isAdmin]);

  const renderSectionContent = () => {
    switch (activeSection) {
      case 'Home':
        return <HomeContent />;
      case 'Manage D3':
        return <SellBuyContent />;
      case 'Admin':
        return isAdmin ? <AdminContent /> : <Typography paragraph>Access Denied</Typography>;
      case 'Lite Paper':
        return <LitePaperContent />;
      case 'FAQ':
        return <FAQContent />;
      case 'Manage Avatar':
        return <ManageAvatarContent />;
      default:
        return <Typography paragraph>Select a section</Typography>;
    }
  };

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          height: '80px',
          marginTop: '1%'
        }}
      >
        <Toolbar className="flex justify-between" style={{ minHeight: '80px' }}>
          <img src={doorsLabLogo} alt="DoorsLab Logo" style={{ height: '52px', width: 'auto' }} className="h-12 mr-4" />
          <Box className="flex items-center" sx={{
            display: 'flex',
            overflowX: { xs: 'auto', md: 'visible' },
            whiteSpace: 'nowrap',
            width: '100%',
            msOverflowStyle: 'none',  // IE and Edge
            scrollbarWidth: 'none',  // Firefox
            '&::-webkit-scrollbar': { display: 'none' },  // Chrome, Safari, and Opera
            '-webkit-overflow-scrolling': 'touch'  // scrolling for touch devices
          }}>
            <Grid className="flex space-x-4">
              {menuItems.map((item) => (
                <button
                  key={item.text}
                  onClick={() => setActiveSection(item.text)}
                  className={`flex items-center px-3 py-2 rounded-md text-sm font-medium ${activeSection === item.text ? 'bg-white text-black' : 'text-white hover:bg-gray-700'}`}
                >
                  {item.icon}
                  {item.text}
                </button>
              ))}
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>

      <Box component="main">
        {renderSectionContent()}
      </Box>
    </Box>
  );
}
