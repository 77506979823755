import React, { useState } from 'react';
import { useWeb3 } from '../hooks/Web3Context';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { CircularProgress, Grid } from '@mui/material';

const BurnButton = ({ tokenId, onBurnSuccess }: { tokenId: number, onBurnSuccess: (tokenId: number) => void }) => {
    const [isHovered, setIsHovered] = useState(false);
    const { burnToken } = useWeb3();
    const [loading, setLoading] = useState(false);

    const handleBurn = async () => {
        setLoading(true);
        const success = await burnToken(tokenId);
        if (success) {
            console.log(`Token ${tokenId} burned successfully.`);
            onBurnSuccess(tokenId); // Appeler le callback lorsque le burn est réussi
        } else {
            console.error(`Failed to burn token ${tokenId}.`);
        }
        setLoading(false);
    };

    if (loading) {
        return <Grid sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress sx={{ color: '#fb553c' }} /></Grid>;
    }

    return (
        <button
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleBurn}
            style={{
                margin: 10,
                backgroundColor: isHovered ? 'rgba(42,42,42,0.8)' : 'white',
                cursor: 'pointer',
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                borderRadius: 30,
                color: 'black',
                fontSize: '16px',
                fontWeight: 'bold'
            }}
        ><DoDisturbOnIcon sx={{ mr: 1 }} />
            Delete
        </button>
    );
};

export default BurnButton;
