import { Grid, Card, Typography } from '@mui/material';
import bgCard from '../images/bgCard.png';

const avatarContractAddress = '0x4cF0c9eCaB344d96D3757725d79f94958036cc8A';

interface AvatarMintedContentProps {
    metadata?: { image?: string } | null; // Allow metadata to be null or undefined
    hash: string;
}

export default function AvatarMintedContent({ metadata = null, hash }: AvatarMintedContentProps) {
    console.log("HASH:", hash);
    console.log("METADATA.IMAGE:", metadata?.image);

    return (
        <Card>
            <Grid container sx={{
                width: '100%',
                borderRadius: 5,
                overflow: 'hidden',
                position: 'relative',
                backgroundColor: 'transparent',
                paddingTop: 5,
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${bgCard})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 0.5,
                    zIndex: 0,
                }
            }}>
                <Grid item xs={12} sm={12} md={12} lg={12} position={'relative'} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pb: 1 }}>
                    <Typography variant="h4" sx={{ marginBottom: 2, pt: 1, textAlign: 'center' }}>Your avatar has been minted successfully</Typography>
                    <Card id="avatarImg" sx={{ margin: '20px' }}>
                        <img src={metadata?.image || bgCard} alt="Avatar" /> {/* Fallback to bgCard if image is undefined */}
                    </Card>
                    <Typography variant="body1" sx={{ marginTop: 2, textAlign: 'center' }}>
                        You can find your NFT here: <a href={`https://polygonscan.com/tx/${hash}`} target="_blank" rel='noreferrer' style={{ color: '#FB553C' }}>Polygon Scan</a> or on <a href={`https://testnets.opensea.io/assets/mumbai/${avatarContractAddress}`} target="_blank" rel='noreferrer' style={{ color: '#FB553C' }}>OpenSea</a> (you may have to refresh the metadata).
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
}
