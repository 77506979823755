import { useState, useEffect, useCallback } from 'react';
import { useWeb3 } from '../hooks/Web3Context';
import { Card, Grid, Typography, CircularProgress, Button, Box, Avatar } from '@mui/material';
import EventEmitter from 'events';
import AvatarUploadContent from './AvatarUploadContent';
import AvatarMintedContent from './AvatarMintedContent';
import bgHome from '../images/bgHome.png';
import bgCard from '../images/bgCard.png';
import DisplayNftContent from './DisplayNftContent';

// Augmenter la limite des écouteurs, si nécessaire
EventEmitter.defaultMaxListeners = 200;

export default function ManageAvatarContent() {
    const [isMinted, setMinted] = useState(false);
    const [reloadCounter, setReloadCounter] = useState(0);
    const [metadata, setMetadata] = useState(null);
    const [hash, setHash] = useState<null | string>(null);
    const { getTokensURI, userAddress } = useWeb3();

    const handleMintSuccess = (metadata: any, hash: string) => {
        setMetadata(metadata);
        setHash(hash);
        setMinted(true);
        setReloadCounter(prev => prev + 1);
    };

    useEffect(() => {
        const setContent = async () => {
            const tokensURI = await getTokensURI(userAddress);
            const response = await fetch(tokensURI[0].tokenURI);
            const data = await response.json();
            setMetadata(data.metadata);
            setHash(data.hash);
            setMinted(true);
            setReloadCounter(prev => prev + 1);
        };
        setContent();
    }, [getTokensURI, userAddress]);

    return (
        <Box sx={{
            position: 'relative',
            minHeight: '100vh',
            width: '100vw',
            overflow: 'hidden'
        }}>
            {/* Fixed background */}
            <Box sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundImage: `url(${bgHome})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
                zIndex: 0
            }} />

            {/* Scrollable content */}
            <Box sx={{
                position: 'relative',
                width: '100%',
                minHeight: '100vh',
                overflowY: 'auto',
                pt: '80px',
                zIndex: 1,
                backgroundColor: 'transparent'
            }}>
                <Grid container spacing={3} sx={{
                    mt: 4,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#ffffff',
                    px: 2,
                    '.MuiCard-root': {
                        backgroundColor: 'transparent',
                        color: '#ffffff',
                        borderRadius: 5
                    },
                    '.MuiTypography-body2': {
                        fontSize: '1.1rem',
                        fontWeight: 400
                    }
                }}>
                    {/* Upload New Avatar Grid */}
                    <Grid item xs={10} sm={10} md={6} lg={4}>
                        {isMinted ? (
                            <AvatarMintedContent metadata={metadata} hash={hash || ''} />
                        ) : (
                            <AvatarUploadContent onMintSuccess={handleMintSuccess} />
                        )}
                    </Grid>
                    {/* Avatar Grid */}
                    <Grid item xs={10} sm={10} md={6} lg={8}>
                        <Grid container spacing={2} sx={{
                            mt: 0,
                            width: '100%',
                            borderRadius: 5,
                            overflow: 'hidden',
                            position: 'relative',
                            backgroundColor: 'transparent',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url(${bgCard})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                opacity: 0.5,
                                zIndex: 0,
                            }
                        }}>
                            <DisplayNftContent reload={reloadCounter} />
                        </Grid>
                    </Grid>
                </Grid >
            </Box >
        </Box>
    );

}
