import { useState, useEffect, useCallback } from 'react';
import { useWeb3 } from '../hooks/Web3Context';
import BurnButton from './BurnButton';
import { Card, Grid, Typography, CircularProgress, Button, Box, Avatar } from '@mui/material';
import EventEmitter from 'events';
import Favorite from '@mui/icons-material/Favorite';

interface DisplayNftContentProps {
    reload: number;
}

const fetchWithRetry = async (url: string, options: any = {}, retries: number = 3, delay: number = 1000): Promise<any> => {
    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        if (retries > 0) {
            console.warn(`Retrying... (${retries} retries left)`);
            await new Promise(res => setTimeout(res, delay));
            return fetchWithRetry(url, options, retries - 1, delay * 2);
        } else {
            throw error;
        }
    }
};

export default function DisplayNftContent({ reload }: DisplayNftContentProps): React.ReactElement {
    const [tokenData, setTokenData] = useState<any[]>([]);
    const [favoriteTokenId, setFavoriteTokenId] = useState<number | null>(null);
    const [tokens, setTokens] = useState<{ tokenId: number, tokenURI: string }[]>([]);
    const [loading, setLoading] = useState(true);
    const { getTokensURI, userAddress } = useWeb3();
    const isSmallScreen = window.innerWidth <= 700;


    const setContent = useCallback(async () => {
        try {
            const tokens = await getTokensURI(userAddress);
            if (tokens && Array.isArray(tokens)) {
                setTokens(tokens);
                const tokenDataPromises = tokens.map(async (token) => {
                    const ipfsUrl = token.tokenURI;
                    const data = await fetchWithRetry(ipfsUrl);
                    return { tokenId: token.tokenId, ...data };
                });
                const data = await Promise.all(tokenDataPromises);
                setTokenData(data);

                // Récupérer l'ID du token favori depuis le localStorage
                const favoriteTokenId = localStorage.getItem('favoriteTokenId');
                if (favoriteTokenId) {
                    setFavoriteTokenId(parseInt(favoriteTokenId));
                }
            } else {
                setTokens([]);
                setTokenData([]);
            }
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch tokens URI:', error);
            setTokens([]);
            setTokenData([]);
            setLoading(false);
        }
    }, [getTokensURI, userAddress]);

    useEffect(() => {
        setContent();

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                setContent();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [setContent, reload]);


    const handleMarkFavorite = (tokenId: number) => {
        setFavoriteTokenId(tokenId);
        localStorage.setItem('favoriteTokenId', tokenId.toString());
    };

    const handleBurnSuccess = (burnedTokenId: number) => {
        setTokenData((prevData) => prevData.filter(token => token.tokenId !== burnedTokenId));
    };

    if (loading) {
        return <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}><CircularProgress sx={{ color: '#fb553c' }} /></Grid>;
    }

    const imgStyles = {
        width: '100%',
        height: 'auto',
        borderRadius: '10',
        maxHeight: isSmallScreen ? '300px' : '450px'
    };

    return (
        <Box sx={{
            position: 'relative',
            zIndex: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 3,
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
                height: '12px',
            },
            scrollbarColor: '#FB553C transparent',
            scrollbarWidth: 'thin'
        }}>
            {tokenData.length === 0 ? (
                <Typography variant="h6" sx={{ color: 'white', textAlign: 'center', width: '100%' }}>
                    No NFT available, please mint your NFT.
                </Typography>
            ) : (
                tokenData.slice().reverse().map((data, index) => (
                    <Box key={index} sx={{ margin: 2, minWidth: '350px', flex: '0 0 auto' }}>
                        <Card sx={{ padding: 3, textAlign: 'center', border: 'solid 1px #fb553c', position: 'relative', minWidth: '350px' }}>
                            <Box sx={{ position: 'relative' }}>
                                <img src={data.image} alt={data.name} style={imgStyles} />
                                <Avatar sx={{ bgcolor: 'rgba(0,0,0,0.5)', position: 'absolute', top: 16, right: 16 }}>
                                    <Favorite
                                        sx={{
                                            color: favoriteTokenId === data.tokenId ? '#fb553c' : 'white',
                                            '&:hover': {
                                                color: favoriteTokenId === data.tokenId ? '#fb553C' : 'gray',
                                            },
                                        }}
                                        onClick={() => handleMarkFavorite(data.tokenId)}
                                    />
                                </Avatar>

                                <Box sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    paddingTop: '60px',
                                    background: 'linear-gradient(to top, rgba(0, 0, 0, 1), transparent)',
                                    borderBottomLeftRadius: 10,
                                    borderBottomRightRadius: 10
                                }}>
                                    <Typography variant="h5" sx={{ color: 'white', padding: '4px 8px', borderRadius: 1 }}>
                                        {data.name}
                                    </Typography>
                                    <BurnButton tokenId={data.tokenId} onBurnSuccess={handleBurnSuccess} />
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                ))
            )}
        </Box>
    );
}