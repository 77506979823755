// AdminContent.tsx
import React, { useState } from 'react';
import { Button, TextField, Typography, Grid, Card, CardContent } from '@mui/material';
import { useWeb3 } from '../hooks/Web3Context';
import '../components/ui/dashboarstyle.css';


export default function AdminContent() {
  const { addAuthorized, removeAuthorized, ConversionRate, changeRewardPercentage, depositFunds } = useWeb3();

  const [addressToAdd, setAddressToAdd] = useState('');
  const [addressToRemove, setAddressToRemove] = useState('');
  const [newConversionRate, setNewConversionRate] = useState('');
  const [newRewardPercentage, setNewRewardPercentage] = useState('');
  const [amountToDeposit, setAmountToDeposit] = useState('');

  const handleAddAuthorizedAddress = async () => {
    await addAuthorized(addressToAdd);
    setAddressToAdd('');
  };

  const handleRemoveAuthorizedAddress = async () => {
    await removeAuthorized(addressToRemove);
    setAddressToRemove('');
  };

  const handleSetConversionRate = async () => {
    await ConversionRate(parseFloat(newConversionRate));
    setNewConversionRate('');
  };

  const handleChangeRewardPercentage = async () => {
    await changeRewardPercentage(parseFloat(newRewardPercentage));
    setNewRewardPercentage('');
  };

  const handleDepositFunds = async () => {
    await depositFunds(amountToDeposit);
    setAmountToDeposit('');
  };

  return (
    <Grid container spacing={3} justifyContent="center" sx={{
      color: 'var(--text-primary)',
      backgroundColor: 'var(--background-default)',
      '.MuiCard-root': { backgroundColor: 'var(--background-paper)' },
      '.MuiButton-root': { 
          backgroundColor: 'var(--primary-main)', 
          color: 'var(--text-primary)',
          '&:hover': {
              backgroundColor: 'var(--primary-dark)'
          }
      },
      '.MuiTextField-root': {
          '& input': { color: 'var(--text-primary)' },
          '& label': { color: 'var(--text-primary)' }
      }
    }}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Admin Panel
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent>
            <Typography variant="h6">Add Authorized Address</Typography>
            <TextField
              label="Address to Add"
              value={addressToAdd}
              onChange={(e) => setAddressToAdd(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button onClick={handleAddAuthorizedAddress} variant="contained" color="primary">
              Add
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent>
            <Typography variant="h6">Remove Authorized Address</Typography>
            <TextField
              label="Address to Remove"
              value={addressToRemove}
              onChange={(e) => setAddressToRemove(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button onClick={handleRemoveAuthorizedAddress} variant="contained" color="secondary">
              Remove
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card>
          <CardContent>
            <Typography variant="h6">Set Conversion Rate</Typography>
            <TextField
              label="New Conversion Rate"
              value={newConversionRate}
              onChange={(e) => setNewConversionRate(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button onClick={handleSetConversionRate} variant="contained" color="primary">
              Update
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card>
          <CardContent>
            <Typography variant="h6">Change Reward Percentage</Typography>
            <TextField
              label="New Reward Percentage"
              value={newRewardPercentage}
              onChange={(e) => setNewRewardPercentage(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button onClick={handleChangeRewardPercentage} variant="contained" color="primary">
              Change
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card>
          <CardContent>
            <Typography variant="h6">Deposit Funds</Typography>
            <TextField
              label="Amount to Deposit"
              value={amountToDeposit}
              onChange={(e) => setAmountToDeposit(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button onClick={handleDepositFunds} variant="contained" color="primary">
              Deposit
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
