// web3Service.ts
import Web3 from 'web3';
import contractABI from '../ContractABI/web3ServiceContractABI.json';
import avatarContractABI from '../ContractABI/AvatarContractABI.json'; // Importez le contrat Avatar pour la méthode safeMint


const contractAddress = "0x54D5Fa75C29c058D2F1Bd9fDffc2E39924b5F35e";
const avatarContractAdress = "0x528D28A79088827e3d1B1779386AB7799266bEB0";

declare let window: any;

export const connectWallet = async (): Promise<string[]> => {
    if (typeof window !== 'undefined' && window.ethereum) {
        try {
            const accounts: string[] = await window.ethereum.request({ method: 'eth_requestAccounts' });
            console.log('Wallet connected:', accounts[0]);
            return accounts;
        } catch (error) {
            console.error('Error connecting to wallet:', error);
            throw new Error('Connection failed');
        }
    } else {
        console.error('Ethereum wallet not detected. Install MetaMask.');
        throw new Error('MetaMask not installed');
    }
};

export const checkNetwork = async (): Promise<string> => {
    if (typeof window !== 'undefined' && window.ethereum) {
        const chainId = await new Web3(window.ethereum).eth.getChainId();
        return chainId.toString();
    } else {
        throw new Error('Ethereum wallet not detected.');
    }
};

export const switchToPolygon = async (): Promise<void> => {
    if (typeof window !== 'undefined' && window.ethereum) {
        const polygonChainId = '0x89'; // Mainnet
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: polygonChainId }],
            });
        } catch (error: any) {
            if (error.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: polygonChainId,
                            chainName: 'Polygon Mainnet',
                            rpcUrls: ['https://polygon-rpc.com/'],
                            nativeCurrency: {
                                name: 'MATIC',
                                symbol: 'MATIC',
                                decimals: 18,
                            },
                            blockExplorerUrls: ['https://polygonscan.com/'],
                        }],
                    });
                } catch (addError) {
                    console.error('Failed to add Polygon network:', addError);
                    throw new Error('Adding Polygon network failed');
                }
            } else {
                console.error('Failed to switch to Polygon network:', error);
                throw new Error('Switching to Polygon network failed');
            }
        }
    } else {
        throw new Error('Ethereum wallet not detected.');
    }
};

export const checkUserRole = async (userAddress: string): Promise<boolean> => {
    if (typeof window !== 'undefined' && window.ethereum) {
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(contractABI, contractAddress);
        try {
            const isAuthorized: boolean = await contract.methods.isAuthorizedAddress(userAddress).call();
            return isAuthorized;
        } catch (error) {
            console.error('Error checking user role:', error);
            throw new Error('Failed to check user role');
        }
    } else {
        throw new Error('Ethereum wallet not detected.');
    }
};

// web3Service.ts
export const checkAdminRole = async (userAddress: string): Promise<boolean> => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(contractABI, contractAddress);
    const ADMIN_ROLE = web3.utils.soliditySha3("ADMIN_ROLE");
    try {
      const isAdmin: boolean = await contract.methods.hasRole(ADMIN_ROLE, userAddress).call();
      return isAdmin;
    } catch (error) {
      console.error('Error checking admin role:', error);
      throw new Error('Failed to check admin role');
    }
  };

  export const getUserTokenBalance = async (userAddress: string): Promise<number> => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(contractABI, contractAddress);
    const balance = await contract.methods.balanceOf(userAddress).call();
    // Convertit directement en Number pour la division, en supposant que le solde n'est pas trop élevé pour JavaScript
    return Number(balance) / 100;
};

export const getContractMATICBalance = async (): Promise<string> => {
    const web3 = new Web3(window.ethereum);
    const balance = await web3.eth.getBalance(contractAddress);
    return web3.utils.fromWei(balance, 'ether');
};

export const getUserMATICShare = async (userAddress: string): Promise<string> => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(contractABI, contractAddress);
    const share = await contract.methods.employeeMATICShare(userAddress).call();
    // Convertir share en chaîne pour assurer la compatibilité
    return web3.utils.fromWei(String(share), 'ether');
};

export const getConversionRate = async (): Promise<number> => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(contractABI, contractAddress);
    try {
        const tokenValue = await contract.methods.conversionRate().call();
        const rate = Number(tokenValue);
        if (isNaN(rate)) {
            throw new Error("Invalid conversion rate");
        }
        return rate;
    } catch (error) {
        console.error('Error fetching conversion rate:', error);
        throw new Error('Failed to fetch conversion rate');
    }
};

export const getStackReward = async (): Promise<number> => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(contractABI, contractAddress);
    try {
        const stackReward = await contract.methods.getStackReward().call();
        return Number(stackReward);
    } catch (error) {
        console.error('Error fetching stack reward:', error);
        throw new Error('Failed to fetch stack reward');
    }
};

export const getStackedTokens = async (userAddress: string): Promise<number> => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(contractABI, contractAddress);
    try {
        const stackedToken = await contract.methods.getStackedTokens(userAddress).call();
        return Number(stackedToken) / 100;
    } catch (error) {
        console.error('Error fetching stacked tokens:', error);
        throw new Error('Failed to fetch stacked tokens');
    }
};

export const getAvailableTokens = async (): Promise<number> => {
    if (typeof window !== 'undefined' && window.ethereum) {
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(contractABI, contractAddress);
        try {
            const availableTokens = await contract.methods.getAvailableTokens().call();
            return Number(availableTokens) / 100; 
        } catch (error) {
            console.error('Error fetching available tokens:', error);
            throw new Error('Failed to fetch available tokens');
        }
    } else {
        throw new Error('Ethereum wallet not detected.');
    }
};

export const buyTokens = async (userAddress: string, tokenAmount: number): Promise<boolean> => {
	const web3 = new Web3(window.ethereum);
	const contract = new web3.eth.Contract(contractABI, contractAddress);
	
	try {
	  const conversionRate = await contract.methods.conversionRate().call();
	  const availableTokens = await contract.methods.getAvailableTokens().call();
  
	  // Conversion explicite en nombre
	  const conversionRateNumber = Number(conversionRate);
	  const availableTokensNumber = Number(availableTokens);
  
	  if (tokenAmount > availableTokensNumber) {
		console.error('Not enough tokens available for purchase');
		return false;
	  }
  
	  // Calcul du montant de MATIC requis pour l'achat
	  const maticAmountRequired = (tokenAmount * 100) / conversionRateNumber;
	  const maticAmountRequiredWei = web3.utils.toWei(maticAmountRequired.toString(), 'ether');
  
	  await contract.methods.buyTokens(tokenAmount * 100).send({
		from: userAddress,
		value: maticAmountRequiredWei,
	  });
  
	  console.log('Tokens purchased successfully');
	  return true;
	} catch (error) {
	  console.error('Error buying tokens:', error);
	  return false;
	}
  };

  export const exchangeTokens = async (userAddress: string, amount: number): Promise<boolean> => {
	const web3 = new Web3(window.ethereum);
	const contract = new web3.eth.Contract(contractABI, contractAddress);
	
	try {
	  const conversionRate = await contract.methods.conversionRate().call();
	  const tokenBalance = await contract.methods.balanceOf(userAddress).call();
	  const shareBalance = await contract.methods.getEmployeeMATICShare(userAddress).call();
  
	  // Assurez-vous que les valeurs sont des nombres
	  const conversionRateNum = Number(conversionRate);
	  const tokenBalanceNum = Number(tokenBalance);
	  const shareBalanceNum = Number(shareBalance);
	  const requiredMaticAmount = (amount * 100) * (1 / conversionRateNum);
	  const requiredMaticAmountWei = web3.utils.toWei(requiredMaticAmount.toString(), 'ether');
  
	  if (tokenBalanceNum < amount * 100) {
		console.error('Insufficient token balance');
		return false;
	  }
  
	  if (shareBalanceNum < Number(requiredMaticAmountWei)) {
		console.error('Insufficient Matic share balance');
		return false;
	  }
  
	  await contract.methods.exchangeTokensForETH(amount * 100).send({ from: userAddress, value: requiredMaticAmountWei });
  
	  console.log('Tokens exchanged successfully');
	  return true;
	} catch (err) {
	  console.error('Error exchanging tokens:', err);
	  return false;
	}
  };

  export const transferTokens = async (senderAddress: string, recipientAddress: string, tokenAmount: number): Promise<boolean> => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(contractABI, contractAddress);
    const gasPrice = await web3.eth.getGasPrice();

    // Obtenez le solde et assurez-vous qu'il est traité comme un nombre.
    const tokenBalanceStr = await contract.methods.balanceOf(senderAddress).call();
    const tokenBalance = Number(tokenBalanceStr);

    if (isNaN(tokenBalance)) {
        console.error('Failed to fetch token balance');
        return false;
    }

    if (tokenBalance < tokenAmount * 100) {
        console.error('Insufficient token balance');
        return false;
    }

    try {
        await contract.methods.transfer(recipientAddress, tokenAmount * 100).send({ from: senderAddress, gasPrice: gasPrice.toString() });
        console.log('Tokens transferred successfully');
        return true;
    } catch (err) {
        console.error('Error transferring tokens:', err);
        return false;
    }
};

export const stackTokens = async (userAddress: string, amount: number): Promise<boolean> => {
	const web3 = new Web3(window.ethereum);
	const contract = new web3.eth.Contract(contractABI, contractAddress);
	const gasPrice = await web3.eth.getGasPrice();
  
	try {
	  await contract.methods.stackTokens(amount * 10 ** 2).send({ from: userAddress, gasPrice: gasPrice.toString() });
	  console.log('Tokens stacked successfully');
	  return true;
	} catch (error) {
	  console.error('Error stacking tokens:', error);
	  return false;
	}
  };
  

  export const claimStackedTokens = async (userAddress: string): Promise<boolean> => {
	const web3 = new Web3(window.ethereum);
	const contract = new web3.eth.Contract(contractABI, contractAddress);
	const gasPrice = await web3.eth.getGasPrice();
  
	try {
	  await contract.methods.claimStackedTokens().send({ from: userAddress, gasPrice: gasPrice.toString() });
	  console.log('Stacked tokens claimed successfully');
	  return true;
	} catch (error) {
	  console.error('Error claiming stacked tokens:', error);
	  return false;
	}
  };

  // Variable pour stocker le hash de la transaction
  let transactionHash: string;

  // Function Mint Tokens
	export const mintTokens = async (userAddress: string,metadataHash: string): Promise<boolean> => {
		const web3 = new Web3(window.ethereum);
		const avatarContract = new web3.eth.Contract(avatarContractABI, avatarContractAdress);
		const gasPrice = await web3.eth.getGasPrice();
    console.log("GASPRICE",gasPrice.toString());

		try {
		await avatarContract.methods.safeMint(userAddress,`https://ipfs.io/ipfs/${metadataHash}`).send({ from: userAddress, gasPrice: gasPrice.toString()}).on('transactionHash', function(hash){
      transactionHash = hash;
		});
		console.log('Tokens minted successfully');
		return true;
		}catch (error) {
		console.error('Error minting tokens:', error);
		return false;
		}
	};

  // Fonction pour recuperer le hash de la transaction
  export const getTransactionHash = async (): Promise<string> => { 
    return transactionHash;
  };

  // Fonction pour recuperer la totalSupply
  export const getTotalSupply = async (): Promise<number> => {
    const web3 = new Web3(window.ethereum);
    const avatarContract = new web3.eth.Contract(avatarContractABI, avatarContractAdress);
    try {
      const totalSupply = await avatarContract.methods.totalSupply().call();
      console.log('Total supply:', totalSupply);
      return Number(totalSupply);
    } catch (error) {
      console.error('Error fetching total supply:', error);
      throw new Error('Failed to fetch total supply');
    }
  };

  // Fonction pour récupérer les tokens d'un propriétaire
  export const getTokensOfOwner = async (userAddress: string): Promise<number[]> => {
    const web3 = new Web3(window.ethereum);
    const avatarContract = new web3.eth.Contract(avatarContractABI, avatarContractAdress);
    try {
      const tokensOfOwner = await avatarContract.methods.tokensOfOwner(userAddress).call();
      console.log('Tokens of owner:', tokensOfOwner);
      return tokensOfOwner?.map((token: string) => Number(token)) || [];
    } catch (error) {
      console.error('Error fetching tokens of owner:', error);
      throw new Error('Failed to fetch tokens of owner');
    }
  };

  // Fonction pour récupérer les tokensURI
  export const getTokensURI = async (userAddress: string): Promise<{ tokenId: number, tokenURI: string }[]> => {
    const web3 = new Web3(window.ethereum);
    const avatarContract = new web3.eth.Contract(avatarContractABI, avatarContractAdress);
    try {
        const tokenIds = await getTokensOfOwner(userAddress);
        const tokenURIs = await Promise.all(tokenIds.map(async (tokenId) => {
          const tokenURI = await avatarContract.methods.tokenURI(tokenId).call() as unknown;
        
          return {
            tokenId,
            tokenURI: typeof tokenURI === 'string' ? tokenURI : '', // Ensure tokenURI is a string or fallback to ''
          };
        }));
        
        return tokenURIs;
    } catch (error) {
        console.error('Error fetching token URIs:', error);
        return []; // Retourner un tableau vide en cas d'erreur
    }
  };

  export const removeAuthorizedAddress = async (adminAddress: string, addressToRemove: string): Promise<boolean> => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(contractABI, contractAddress);
    const gasPrice = await web3.eth.getGasPrice();
    
    try {
      await contract.methods.removeAuthorizedAddress(addressToRemove).send({ from: adminAddress, gasPrice: gasPrice.toString() });
      console.log(`Address ${addressToRemove} removed from authorized.`);
      return true;
    } catch (error) {
      console.error('Error removing authorized address:', error);
      return false;
    }
  };
  
  export const addAuthorizedAddress = async (adminAddress: string, addressToAdd: string): Promise<boolean> => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(contractABI, contractAddress);
    const gasPrice = await web3.eth.getGasPrice();
    
    try {
      await contract.methods.addAuthorizedAddress(addressToAdd).send({ from: adminAddress, gasPrice: gasPrice.toString() });
      console.log(`Address ${addressToAdd} added to authorized.`);
      return true;
    } catch (error) {
      console.error('Error adding authorized address:', error);
      return false;
    }
  };
  
  
  export const setConversionRate = async (userAddress: string, newRate: number): Promise<boolean> => {
	const web3 = new Web3(window.ethereum);
	const contract = new web3.eth.Contract(contractABI, contractAddress);
	const gasPrice = await web3.eth.getGasPrice();
  
	try {
	  await contract.methods.setConversionRate(newRate).send({ from: userAddress, gasPrice: gasPrice.toString() });
	  console.log('Conversion rate updated successfully');
	  return true;
	} catch (error) {
	  console.error('Error setting conversion rate:', error);
	  return false;
	}
  };
  
  export const changeRewardPercentage = async (userAddress: string, percentage: number): Promise<boolean> => {
	const web3 = new Web3(window.ethereum);
	const contract = new web3.eth.Contract(contractABI, contractAddress);
	const gasPrice = await web3.eth.getGasPrice();
  
	try {
	  await contract.methods.setStackingRewardPercentage(percentage).send({ from: userAddress, gasPrice: gasPrice.toString() });
	  console.log('Reward percentage changed successfully');
	  return true;
	} catch (error) {
	  console.error('Error changing reward percentage:', error);
	  return false;
	}
  };

  export const depositFunds = async (userAddress: string, amount: string): Promise<boolean> => {
	const web3 = new Web3(window.ethereum);
	const contract = new web3.eth.Contract(contractABI, contractAddress);
	const depositAmountInWei = web3.utils.toWei(amount, 'ether');
	const gasPrice = await web3.eth.getGasPrice();
  
	try {
	  await contract.methods.deposit().send({ from: userAddress, value: depositAmountInWei, gasPrice: gasPrice.toString() });
	  console.log('Funds deposited successfully');
	  return true;
	} catch (error) {
	  console.error('Error depositing funds:', error);
	  return false;
	}
  };

  export const getRemainingStackingTime = async (userAddress: string): Promise<number> => {
	const web3 = new Web3(window.ethereum);
	const contract = new web3.eth.Contract(contractABI, contractAddress);
	try {
	  const remainingTime = await contract.methods.getRemainingStackingTime(userAddress).call();
	  return Number(remainingTime);
	} catch (error) {
	  console.error('Error fetching remaining stacking time:', error);
	  throw new Error('Failed to fetch remaining stacking time');
	}
  };


export const burnToken = async (userAddress: string, tokenId: number): Promise<boolean> => {
  const web3 = new Web3(window.ethereum);
  const avatarContract = new web3.eth.Contract(avatarContractABI, avatarContractAdress);
  const gasPrice = await web3.eth.getGasPrice();

  try {
    await avatarContract.methods.burn(tokenId).send({ from: userAddress, gasPrice: gasPrice.toString() });
    console.log('Token burned successfully');
    return true;
  } catch (error) {
    console.error('Error burning token:', error);
    return false;
  }
};
