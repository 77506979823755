import { Grid, Paper, Typography, List, ListItem, ListItemText, Box, Card } from '@mui/material';
import bgLitePaper from '../images/bgLitePaper.png';
import banner from '../images/bannerLitePaper.png';

export default function LitePaperContent() {
    return (
        <Box sx={{
            backgroundImage: `url(${bgLitePaper})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            position: 'fixed', // Fix the Box to the viewport
            top: 0,
            left: 0
        }} >
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={8} lg={8}>

                    <Paper sx={{ mt: 2, backgroundColor: '#FFFFFF', overflowY: 'auto', color: 'black', maxHeight: '80vh', scrollbarWidth: 'none', borderRadius: '20px' }}>

                        {/* Part 1: Introduction */}
                        <img src={banner} alt="banner doors3" style={{ width: '100%' }} />
                        <Typography variant="h2" align="center" mb={8} sx={{ fontWeight: 'bold' }}>Lite Paper of the $Doors3 token</Typography>
                        <Card sx={{ px: 1 }}>
                            <Typography variant="h4" mb={4}>1. Introduction</Typography>
                            <Typography variant="body1">
                                $Doors3 is a utility token designed to experiment with some ways to revolutionize the work ecosystem within a specific company. It aims to facilitate employee compensation, engagement and participation while providing a range of unique benefits.
                                <br />
                                <br />
                                Developed and live on <b>Polygon blockchain.</b>
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary={<Typography sx={{ fontWeight: 'bold' }}>Admin Address:</Typography>} secondary={<Typography sx={{ color: 'black' }}>0xaeDE26358Ff6cF7aC5a8C76E0Dfdf22edD7C209a</Typography>} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={<Typography sx={{ fontWeight: 'bold' }}>Smart Contract Address:</Typography>} secondary={<Typography sx={{ color: 'black' }}>0x54D5Fa75C29c058D2F1Bd9fDffc2E39924b5F35e</Typography>} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={<Typography sx={{ fontWeight: 'bold' }}>Reserve Address:</Typography>} secondary={<Typography sx={{ color: 'black' }}>0xaeDE26358Ff6cF7aC5a8C76E0Dfdf22edD7C209a</Typography>} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={<Typography sx={{ fontWeight: 'bold' }}>DAO Address:</Typography>} secondary={<Typography sx={{ color: 'black' }}>0x4374684705606c4ec7b1CCe965636405b2128E75</Typography>} />
                                </ListItem>
                            </List>
                            <Typography component="div" variant="body1" mb={8}>
                                Concept:
                                <br />
                                <b>Fair share powered by Web3</b>
                                <br />
                                The value of the token is defined by the owner, through a coefficient that can be changed over time. Access to exchanges for cashout (MATIC*) is equitable among employees.
                            </Typography>

                            {/* Part 2: Value and Usage */}
                            <Typography variant="h4" mb={4}>2. Value and Usage</Typography>
                            <Typography variant="body1">
                                $Doors3 generates value through its multiple use cases:
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary={<Typography sx={{ fontWeight: 'bold' }}>Liquidity:</Typography>} secondary={<Typography sx={{ color: 'black' }}>Tokens can be exchanged for MATIC, providing a liquidity option for holders.</Typography>} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={<Typography sx={{ fontWeight: 'bold' }}>Governance:</Typography>} secondary={<Typography sx={{ color: 'black' }}>Eventually, the token will allow its holders to participate in the governance of the company via a DAO system, offering voting rights on various decisions.</Typography>} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={<Typography sx={{ fontWeight: 'bold' }}>Marketplace:</Typography>} secondary={<Typography sx={{ color: 'black' }}>An exclusive store will be set up, accessible only to token holders, where they will be able to purchase the companys merchandising and other goods or services.</Typography>} />
                                </ListItem>
                            </List>

                            {/* Part 3: Distribution */}
                            <Typography variant="h4" mb={4} mt={8}>3. Distribution</Typography>
                            <Typography variant="body1">
                                The distribution of $Doors3 is organized to encourage employee engagement and retention:
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary={<Typography sx={{ fontWeight: 'bold' }}>Company Capital:</Typography>} secondary={<List sx={{ color: 'black' }}>
                                        <ListItem>
                                            <ListItemText primary="Quantity: 10 million" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="Use : tokens are held by associates, representing the company's capital." />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="Timing: total amount created on day 1" />
                                        </ListItem>
                                    </List>} />
                                </ListItem>

                                <ListItem>
                                    <ListItemText primary={<Typography sx={{ fontWeight: 'bold' }}>Token Distribution:</Typography>} secondary={<List sx={{ color: 'black' }}>
                                        <ListItem>
                                            <ListItemText primary="Quantity: 10 million" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="Use: tokens are dedicated for distribution, especially to employees on a weekly basis based on time worked and goals achieved." />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="Timing: from 0 to total amount, created on the fly to match distribution needs" />
                                        </ListItem>
                                    </List>} />
                                </ListItem>

                                <ListItem>
                                    <ListItemText primary={<Typography sx={{ fontWeight: 'bold' }}>Reserve:</Typography>} secondary={<List sx={{ color: 'black' }}>
                                        <ListItem>
                                            <ListItemText primary="Quantity: 1 million" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="Use: tokens are held in reserve for future, unanticipated uses, providing flexibility to meet changing business needs." />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="Timing: from 0 to total amount, created on the fly to match distribution needs" />
                                        </ListItem>
                                    </List>} />
                                </ListItem>
                            </List>
                            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 8 }}>
                                <img style={{ width: '50%', borderRadius: 10 }} src='/pieChartLitePaper.png' alt='PieChart TokenDistibution' />
                            </Box>

                            {/* Part 4: Stacking */}
                            <Typography variant="h4" mb={4}>4. Stacking</Typography>
                            <Typography variant="body1">
                                $Doors3 offers an innovative stacking mechanism:
                                <br />
                                Employees can lock their tokens for a defined period.
                                At the end of this period, they can get their tokens back with a % bonus, thus encouraging savings and retention.
                                <br />
                                Provided from the Token Distribution pool.
                                <br />
                                <br />
                                V1:
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary="lock period: 6 month" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="APR: 10% " />
                                </ListItem>
                            </List>
                            <Typography variant="body1" mb={8}>
                                <b>Be aware that you can only call the stacking mechanism one time for the 6 following months. Choose carefully your allocation before submitting.</b>
                            </Typography>

                            {/* Part 5: Exchange for MATIC */}
                            <Typography variant="h4" mb={4}>5. Exchange for MATIC</Typography>
                            <Typography variant="body1">
                                The $Doors3 offers the possibility to exchange tokens for MATIC at a conversion rate determined by the administrator based on the companys performance. This creates a direct link between the success of the business and the value of the token.
                                <br />
                                <br />
                                V1:
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Initial conversion rate : 1$D3 = 0.01 MATIC" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="APR: 10% " />
                                </ListItem>
                            </List>
                            <Typography variant="body1">
                                <b>Target at 6,5 years: 1$D3 = 1 MATIC</b>
                            </Typography>
                        </Card>
                    </Paper>
                </Grid>
            </Grid >
        </Box>
    );
}