import React from 'react';
import { Grid, Card, CardHeader, CardContent, Typography, Avatar, Box } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useWeb3 } from '../hooks/Web3Context';
import bgHome from '../images/bgHome.png';
import bgCard from '../images/bgCard.png';
import AvatarContent from './AvatarContent';
import DaoContent from './DAO';

export default function HomeContent() {
  const { userAddress, userTokenBalance, userMATICShare } = useWeb3();

  const handleCopy = () => {
    navigator.clipboard.writeText(userAddress).then(() => {
      alert('Address copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <Box sx={{
      position: 'relative',
      minHeight: '100vh',
      width: '100vw',
      overflow: 'hidden'
    }}>
      {/* Fixed background */}
      <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${bgHome})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        zIndex: 0
      }} />

      {/* Scrollable content */}
      <Box sx={{
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        overflowY: 'auto',
        pt: '80px',
        zIndex: 1,
        backgroundColor: 'transparent'
      }}>
        <Grid container spacing={4} sx={{
          width: '100%',
          marginTop: 2,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          color: '#ffffff',
          px: 2,
          '.MuiCard-root': {
            mb: 2,
            color: '#ffffff',
            borderRadius: 5
          },
          '.MuiCardHeader-root': {
            pb: 0
          },
          '.MuiCardContent-root': {
            pt: 0
          },
          '.MuiTypography-body2': {
            fontSize: '1.1rem',
            fontWeight: 400
          }
        }}>
          {/* Colonne Avatar */}
          <Grid item xs={12} sm={8} md={4} lg={4} sx={{ position: 'relative', overflow: 'hidden' }}>
            <Box sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              borderRadius: 5,
              '&::before': {
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundImage: `url(${bgCard})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                opacity: 0.5,
                zIndex: 0,
                borderRadius: 5
              }
            }}>
              <Box sx={{ position: 'relative', zIndex: 1, width: '100%', padding: 2 }}>
                <Card sx={{ backgroundColor: '#fb553C' }}>
                  <Typography variant="h6" sx={{ textAlign: 'center', my: 2 }}>Avatar</Typography>
                </Card>
                <AvatarContent />
              </Box>
            </Box>
          </Grid>

          {/* Column for Information Cards */}
          <Grid item xs={12} sm={8} md={4} lg={4} sx={{ position: 'relative', overflow: 'hidden' }}>
            <Box sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              borderRadius: 5,
              '&::before': {
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundImage: `url(${bgCard})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                opacity: 0.5,
                zIndex: 0,
                borderRadius: 5
              }
            }}>
              <Box sx={{ position: 'relative', zIndex: 1, width: '100%', padding: 2 }}>
                <Card sx={{ mb: 2, backgroundColor: '#fb553C', borderRadius: 5 }}>
                  <Typography variant="h6" sx={{ textAlign: 'center', my: 2 }}>Wallet Informations</Typography>
                </Card>
                <Grid container direction="column" spacing={2}>
                  {/* Address Card */}
                  <Grid item>
                    <Card sx={{ bgcolor: 'transparent', border: '1px solid white', borderRadius: 5 }}>
                      <CardHeader
                        avatar={<Avatar sx={{ bgcolor: '#fb553C' }}><AccountCircleIcon /></Avatar>}
                        title={<Typography variant="subtitle1" component="div">Address</Typography>}
                      />
                      <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>{userAddress}</Typography>
                        <Avatar sx={{ bgcolor: 'transparent', ml: 2, cursor: 'pointer' }} onClick={handleCopy}><ContentCopyIcon /></Avatar>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Balance Card */}
                  <Grid item>
                    <Card sx={{ bgcolor: 'transparent', border: '1px solid white', borderRadius: 5 }}>
                      <CardHeader
                        avatar={<Avatar sx={{ bgcolor: '#fb553C' }}><AccountBalanceWalletIcon /></Avatar>}
                        title={<Typography variant="subtitle1" component="div">Balance</Typography>}
                      />
                      <CardContent>
                        <Typography variant="body2">{userTokenBalance} D3 Token</Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* MATIC Share Card */}
                  <Grid item>
                    <Card sx={{ bgcolor: 'transparent', border: '1px solid white', borderRadius: 5 }}>
                      <CardHeader
                        avatar={<Avatar sx={{ bgcolor: '#fb553C' }}><ShareIcon /></Avatar>}
                        title={<Typography variant="subtitle1" component="div">MATIC Share</Typography>}
                      />
                      <CardContent>
                        <Typography variant="body2">{userMATICShare} Matic</Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Contract Address Card */}
                  <Grid item>
                    <Card sx={{ bgcolor: 'transparent', border: '1px solid white', borderRadius: 5 }}>
                      <CardHeader
                        avatar={<Avatar sx={{ bgcolor: '#fb553C' }}><AlternateEmailIcon /></Avatar>}
                        title={<Typography variant="subtitle1" component="div">Smart Contract Address</Typography>}
                      />
                      <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>0x54D5Fa75C29c058D2F1Bd9fDffc2E39924b5F35e</Typography>
                        <Avatar sx={{ bgcolor: 'transparent', ml: 2, cursor: 'pointer' }} onClick={handleCopy}><ContentCopyIcon /></Avatar>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>

          {/* Grid list DAO */}
          <Grid item xs={12} sm={8} md={4} lg={4} sx={{ position: 'relative', overflow: 'hidden' }}>
            <Box sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              borderRadius: 5,
              '&::before': {
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundImage: `url(${bgCard})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                opacity: 0.5,
                zIndex: 0,
                borderRadius: 5
              }
            }}>
              <Box sx={{ position: 'relative', zIndex: 1, width: '100%', padding: 2 }}>
                <Card sx={{ mb: 2, bgcolor: '#fb553C', borderRadius: 5 }}>
                  <Typography variant="h6" sx={{ textAlign: 'center', my: 2 }}>DAO</Typography>
                </Card>
                <DaoContent /> {/* Ajout de l'objet DaoContent via import DAO.tsx */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
