import React from 'react';
import { useWeb3 } from '../hooks/Web3Context';
import Dashboard from './Dashboard';
import doorsLabLogo from '../images/doorslablogo.png';

interface MainProps {
  onConnect: () => void;
}

const Main: React.FC<MainProps> = ({ onConnect }) => {
  const { initiateConnection, isWalletConnected } = useWeb3();

  const handleConnect = async () => {
    await initiateConnection();
    onConnect();
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '100vh',
    }}>
      {/* Logo at the top center, larger and fixed */}
      <img 
        src={doorsLabLogo} 
        alt="DoorsLab Logo" 
        style={{
          width: '300px', // Increase the logo size
          marginTop: '20px',
          marginBottom: '40px',
          position: 'fixed',
          top: '20px', // Position it close to the top
          left: '50%',
          transform: 'translateX(-50%)', // Center horizontally
        }} 
      />

      {/* Center the button in the remaining screen area */}
      {!isWalletConnected ? (
        <div style={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center', // Center vertically
        }}>
          <button
            onClick={handleConnect}
            style={{
              padding: '20px 40px',
              fontSize: '20px',
              fontWeight: 'bold',
              cursor: 'pointer',
              color: '#fff',
              background: 'linear-gradient(to right, #fb553c, #e54d2f, #d04825)',
              border: 'none',
              borderRadius: '8px',
              boxShadow: '0px 4px 10px rgba(251, 85, 60, 0.5)',
              transition: 'background 0.3s ease',
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.background = 'linear-gradient(to right, #e54d2f, #d04825, #bf3e1a)';
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.background = 'linear-gradient(to right, #fb553c, #e54d2f, #d04825)';
            }}
          >
            Connect Wallet
          </button>
        </div>
      ) : (
        <Dashboard />
      )}
    </div>
  );
};

export default Main;
