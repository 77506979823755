import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Main from './components/Main';
import Dashboard from './components/Dashboard';
import { Web3Provider } from './hooks/Web3Context';

function App() {
  const [isConnected, setIsConnected] = useState(false);

  return (
    <BrowserRouter>
      <Web3Provider>
        <Routes>
          {/* Serve Main at / and pass down the setIsConnected function */}
          <Route 
            path="/" 
            element={<Main onConnect={() => setIsConnected(true)} />} 
          />

          {/* Only allow /dashboard access if connected */}
          <Route 
            path="/dashboard" 
            element={isConnected ? <Dashboard /> : <Navigate to="/" replace />} 
          />
        </Routes>
      </Web3Provider>
    </BrowserRouter>
  );
}

export default App;
