import React, { useState } from 'react';

interface VoteButtonProps {
    proposalId: number; // Change to number
    option: string;
    handleVote: (proposalId: number, option: string) => void;
  }
  
const VoteButton: React.FC<VoteButtonProps> = ({ proposalId, option, handleVote }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      onClick={() => handleVote(proposalId, option)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        margin: 2,
        marginLeft: 10,
        backgroundColor: isHovered ? '#c93d20' : '#fb553c',
        cursor: 'pointer',
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 5,
        color: 'white',
        fontWeight: 'bold'
      }}
    >
      {option}
    </button>
  );
};

export default VoteButton;
