import DAOContractABI from '../ContractABI/DAOContractABI.json';
import { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Button, Typography, CircularProgress, Box, Avatar } from '@mui/material';
import CheckedIcon from '@mui/icons-material/TaskAlt';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { useWeb3 } from '../hooks/Web3Context';
import DaoButton from './DaoButton';
import Web3 from 'web3';


declare let window: any;

const DAOContractAddress = "0x4374684705606c4ec7b1CCe965636405b2128E75";

interface Proposal {
	id: number;
	description: string;
	options: string[];
	currentWinningOption: string;
	currentWinningVoteCount: number;
	endTime: number;
	executed: boolean;
}

function DaoContent() {
	const { userAddress } = useWeb3();
	const [proposals, setProposals] = useState<Proposal[]>([]);
	const [loading, setLoading] = useState(true);
	const [isOwner, setIsOwner] = useState(false);

	const web3 = new Web3(window.ethereum);
	const daoContract = new web3.eth.Contract(DAOContractABI, DAOContractAddress);
	const fetchProposals = async () => {
		setLoading(true);
		try {
			const proposalsCountStr: string = await daoContract.methods.proposalCounter().call();
			const proposalsCount: number = parseInt(proposalsCountStr, 10);
			const fetchedProposals: Proposal[] = [];

			for (let i = 1; i <= proposalsCount; i++) {
				const proposalRaw: any = await daoContract.methods.proposals(i).call();
				const options: string[] = await daoContract.methods.getProposalOptions(i).call();

				// Vérifiez que proposalRaw et options ne sont pas undefined
				if (proposalRaw && Array.isArray(options)) {
					const proposalData: Proposal = {
						id: parseInt(proposalRaw.id, 10),
						description: proposalRaw.description,
						options: options,
						currentWinningOption: proposalRaw.currentWinningOption,
						currentWinningVoteCount: parseInt(proposalRaw.currentWinningVoteCount, 10),
						endTime: parseInt(proposalRaw.endTime, 10),
						executed: proposalRaw.executed,
					};

					fetchedProposals.push(proposalData);
				} else {
					console.error('Invalid proposal data or options:', { proposalRaw, options });
				}
			}

			setProposals(fetchedProposals);
		} catch (error) {
			console.error('Failed to fetch proposals:', error);
			setProposals([]);
		}
		setLoading(false);
	};

	const handleVote = async (proposalId: number, option: string) => {
		try {
			await daoContract.methods.vote(proposalId, option).send({ from: userAddress });
			console.log('Vote successful');
			fetchProposals(); // Reload proposals after voting
		} catch (error) {
			console.error('Failed to submit vote:', error);
		}
	};

	const handleCloseVote = async (proposalId: number) => {
		try {
			await daoContract.methods.executeProposal(proposalId).send({ from: userAddress });
			console.log('Vote closed successfully');
			fetchProposals(); // Reload proposals after closing
		} catch (error) {
			console.error('Failed to close vote:', error);
		}
	};

	const checkOwner = async () => {
		try {
			const ownerAddress: string = await daoContract.methods.owner().call();
			setIsOwner(ownerAddress.toLowerCase() === userAddress.toLowerCase());
		} catch (error) {
			console.error('Failed to check owner address:', error);
			setIsOwner(false); // Default to false in case of error
		}
	};

	useEffect(() => {
		fetchProposals();
		checkOwner();
	}, [userAddress]);

	if (loading) {
		return <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}><CircularProgress sx={{ color: '#fb553c' }} /></Grid>;
	}

	const ongoingProposals = proposals.filter(proposal => proposal.endTime > Math.floor(Date.now() / 1000));
	const terminatedProposals = proposals.filter(proposal => proposal.endTime <= Math.floor(Date.now() / 1000));

	return (
		<Grid container spacing={2} sx={{
			height: 600,
			overflowY: 'auto',
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			scrollbarColor: '#FB553C transparent',
			scrollbarWidth: 'thin',
		}}>
			<Grid item xs={12}>
				<Typography variant="h4" sx={{ color: 'white', mb: 2 }}>Ongoing Votes</Typography>
				<Grid container spacing={2}>
					{ongoingProposals.length > 0 ? (
						ongoingProposals.map((proposal) => (
							<Grid item xs={12} sm={12} md={12} key={proposal.id}>
								<Card sx={{ backgroundColor: '#424242', mb: 2 }}>
									<CardContent sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
										<Avatar sx={{ bgcolor: '#fb553c', mr: 1 }}><RotateRightIcon /></Avatar>
										<Grid>
											<Typography variant="h6" sx={{ color: 'white' }}>{proposal.description}</Typography>
											<Typography variant="body2" sx={{ color: 'white' }}>Ends: {new Date(proposal.endTime * 1000).toLocaleString()}</Typography>
											<Grid container spacing={1}>
												{proposal.options.map((option: string, idx: number) => (
													<Grid item xs={6} sm={6} md={6} lg={6} key={`${proposal.id}-${idx}`}>
														<DaoButton
															key={`${proposal.id}-${idx}`}
															proposalId={proposal.id}
															option={option}
															handleVote={handleVote}
														/>
													</Grid>
												))}
											</Grid>
										</Grid>
									</CardContent>
								</Card>
							</Grid>
						))
					) : (
						<Typography sx={{ color: 'white', ml: 2 }}>No ongoing votes available</Typography>
					)}
				</Grid>
			</Grid>

			<Grid item xs={12} >
				<Typography variant="h4" sx={{ color: 'white', mt: 4, mb: 2 }}>Terminated Votes</Typography>
				<Grid container direction='column-reverse' spacing={2}> {/* Ajout de direction="column" Afin d'avoir un element par ligne*/}
					{terminatedProposals.length > 0 ? (
						terminatedProposals.map((proposal) => (
							<Grid item xs={12} sm={6} md={4} key={proposal.id} >
								<Card sx={{ backgroundColor: '#424242' }}>
									<CardContent sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
										<Avatar sx={{ bgcolor: '#fb553c', mr: 1 }}><CheckedIcon /></Avatar>
										<Grid>
											<Typography variant="h6" sx={{ color: 'white' }}>{proposal.description}</Typography>
											<Typography variant="body2" sx={{ color: 'white' }}>Ended: {new Date(proposal.endTime * 1000).toLocaleString()}</Typography>
											<Typography variant="body1" sx={{ mt: 2, color: 'white' }}>
												Winning Option: {proposal.currentWinningOption || 'N/A'}
											</Typography>
										</Grid>
										{!proposal.executed && isOwner && (
											<Button
												variant="contained"
												onClick={() => handleCloseVote(proposal.id)}
												sx={{ mt: 2, backgroundColor: '#f44336' }}
											>
												End vote
											</Button>
										)}
									</CardContent>
								</Card>
							</Grid>
						))
					) : (
						<Typography sx={{ color: 'white', ml: 2 }}>No terminated votes available</Typography>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}

export default DaoContent;