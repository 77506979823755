// hooks/Web3Context.tsx
import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import * as web3Service from '../utils/web3Service';
import { useNavigate } from 'react-router-dom';
import { burnToken } from '../utils/web3Service';

// Définissez le type pour les valeurs de contexte pour TypeScript
interface IWeb3Context {
  isWalletConnected: boolean;
  userAddress: string;
  userRole: boolean | null;
  isAdmin: boolean | null;
  userTokenBalance: number;
  userMATICShare: string;
  contractMATICBalance: string;
  conversionRate: number;
  stackReward: number;
  stackedTokens: number;
  expectedRedemption: number;
  availableTokens: number;
  remainingStackingTime: number;
  initiateConnection: () => Promise<void>;
  updateData: (address: string) => Promise<void>;
  buy: (tokenAmount: number) => Promise<boolean>;
  sellTokens: (amount: number) => Promise<boolean>;
  transferTokens: (recipientAddress: string, tokenAmount: number) => Promise<boolean>;
  stackTokens: (amount: number) => Promise<boolean>;
  claimStackedTokens: () => Promise<boolean>;
  mintTokens: (userAddress: string, metadataHash: string) => Promise<boolean>;
  getTransactionHash: () => Promise<string>;
  getTotalSupply: () => Promise<number>;
  getTokensOfOwner: (address: string) => Promise<number[]>;
  getTokensURI: (address: string) => Promise<{ tokenId: number, tokenURI: string }[]>;
  addAuthorized: (addressToAdd: string) => Promise<boolean>;
  removeAuthorized: (addressToRemove: string) => Promise<boolean>;
  depositFunds: (amount: string) => Promise<boolean>;
  ConversionRate: (newRate: number) => Promise<boolean>;
  changeRewardPercentage: (percentage: number) => Promise<boolean>;
  burnToken: (tokenId: number) => Promise<boolean>;
}

// Créez un contexte avec une valeur par défaut explicite
const Web3Context = createContext<IWeb3Context | undefined>(undefined);


export const Web3Provider = ({ children }: { children: ReactNode }) => {
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [userAddress, setUserAddress] = useState('');
  const [userRole, setUserRole] = useState<boolean | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [userTokenBalance, setUserTokenBalance] = useState(0);
  const [userMATICShare, setUserMATICShare] = useState('');
  const [contractMATICBalance, setContractMATICBalance] = useState('');
  const [conversionRate, setConversionRate] = useState(0);
  const [stackReward, setStackReward] = useState(0);
  const [stackedTokens, setStackedTokens] = useState(0);
  const [expectedRedemption, setExpectedRedemption] = useState(0);
  const [availableTokens, setAvailableTokens] = useState(0);
  const [remainingStackingTime, setRemainingStackingTime] = useState(0);

  const navigate = useNavigate();



  const { connectWallet, checkNetwork, switchToPolygon, checkUserRole, checkAdminRole, getUserTokenBalance, getUserMATICShare, getContractMATICBalance, getConversionRate, getStackReward, getStackedTokens, getAvailableTokens, exchangeTokens, removeAuthorizedAddress, burnToken } = web3Service;

  const initiateConnection = useCallback(async () => {
    try {
      const accounts = await connectWallet();
      if (accounts.length > 0) {
        setUserAddress(accounts[0]);
        console.log(userAddress);
        const chainId = await checkNetwork();
        if (chainId !== '0x89') { // ID de chaîne pour Polygon Mainnet
          await switchToPolygon();
        }
        setIsWalletConnected(true);

        const isAuthorized = await checkUserRole(accounts[0]);
        setUserRole(isAuthorized);

        const isAdminAccount = await checkAdminRole(accounts[0]);
        setIsAdmin(isAdminAccount);

        if (isAuthorized || isAdminAccount) {
          navigate('/dashboard');
          updateData(accounts[0]);
        } else {
          // Gestion d'un utilisateur non autorisé
          alert("You are not authorized");
        }
      }
    } catch (error) {
      console.error("Web3 Initialization Error: ", error);
    }
  }, []);

  const updateData = async (address: string) => {
    try {
      const tokenBalance = await getUserTokenBalance(address);
      setUserTokenBalance(tokenBalance);
      console.log(`Token Balance: ${tokenBalance} $D3`);

      const maticShare = await getUserMATICShare(address);
      setUserMATICShare(maticShare);
      console.log(`User MATIC Share: ${maticShare} MATIC`);

      const maticBalance = await getContractMATICBalance();
      setContractMATICBalance(maticBalance);
      console.log(`Contract MATIC Balance: ${maticBalance} MATIC`);

      const rate = await getConversionRate();
      setConversionRate(rate);
      console.log(`Conversion Rate: 1 D3 = ${100 / rate} MATIC`);

      const stackRewardValue = await getStackReward();
      setStackReward(stackRewardValue);
      console.log(`Stack Reward: ${stackRewardValue}%`);

      const stackedTokensValue = await getStackedTokens(address);
      setStackedTokens(stackedTokensValue);
      console.log(`Stacked Tokens: ${stackedTokensValue} $D3`);

      const reward = (stackedTokensValue) * (stackRewardValue);
      setExpectedRedemption(reward + (stackedTokensValue));
      console.log(`Expected Redemption: ${reward + (stackedTokensValue)} $D3`);

      const remainingTime = await web3Service.getRemainingStackingTime(address);
      setRemainingStackingTime(remainingTime);

      const tokensAvailable = await getAvailableTokens();
      setAvailableTokens(tokensAvailable);
      console.log(`Available Tokens: ${tokensAvailable} $D3`);
    } catch (error) {
      console.error("Error updating data: ", error);
    }

  };

  const buy = async (tokenAmount: number): Promise<boolean> => {
    if (!userAddress) {
      console.error("User address is not defined.");
      return false;
    }

    try {
      const success = await web3Service.buyTokens(userAddress, tokenAmount);
      if (success) {
        console.log("Token purchase was successful.");
        await updateData(userAddress);
        return true;
      } else {
        console.error("Failed to purchase tokens.");
        return false;
      }
    } catch (error) {
      console.error("An error occurred while buying tokens:", error);
      return false;
    }
  };

  const sellTokens = async (amount: number) => {
    if (!userAddress) {
      console.error("User address is not defined.");
      return false;
    }
    const success = await exchangeTokens(userAddress, amount);
    if (success) {
      console.log("Tokens sold successfully.");
      await updateData(userAddress);
      return true;
    } else {
      console.error("Failed to sell tokens.");
      return false;
    }
  }

  const transferTokens = async (recipientAddress: string, tokenAmount: number): Promise<boolean> => {
    if (!userAddress) {
      console.error("User address is not defined.");
      return false;
    }
    const success = await web3Service.transferTokens(userAddress, recipientAddress, tokenAmount);
    if (success) {
      console.log("Tokens transferred successfully.");
      await updateData(userAddress);
      return true;
    } else {
      console.error("Failed to transfer tokens.");
      return false;
    }
  };

  const stackTokens = async (amount: number): Promise<boolean> => {
    const success = await web3Service.stackTokens(userAddress, amount);
    if (success) {
      console.log("Stacking successful.");
      await updateData(userAddress);
      return true;
    }
    console.error("Failed to stack tokens.");
    return false;
  };

  const claimStackedTokens = async (): Promise<boolean> => {
    const success = await web3Service.claimStackedTokens(userAddress);
    if (success) {
      console.log("Tokens claimed successfully.");
      await updateData(userAddress);
      return true;
    }
    console.error("Failed to claim tokens.");
    return false;
  };

  // Ajoutez la fonction mintTokens au contexte
  const mintTokens = async (userAddress: string, metadataHash: string): Promise<boolean> => {
    const success = await web3Service.mintTokens(userAddress, metadataHash);
    if (success) {
      console.log("Tokens minted successfully.");
      await updateData(userAddress);
      return true;
    }
    console.error("Failed to mint tokens.");
    return false;
  };

  // Ajoutez la fonction getTransactionHash au contexte
  const getTransactionHash = async (): Promise<string> => {
    return web3Service.getTransactionHash();
  };

  // Ajoutez la fonction getTotalSupply au contexte
  const getTotalSupply = async (): Promise<number> => {
    return web3Service.getTotalSupply();
  };

  // Ajoutez la fonction getTokensOfOwner au contexte
  const getTokensOfOwner = async (address: string): Promise<number[]> => {
    return web3Service.getTokensOfOwner(address);
  };
  
  const addAuthorized = async (addressToAdd: string): Promise<boolean> => {
  if (!userAddress) {
    console.error("Admin address is not defined.");
    return false;
  }
  return await web3Service.addAuthorizedAddress(userAddress, addressToAdd);
};


  // Ajoutez la fonction getTokensURI au contexte
  const getTokensURI = async (address: string): Promise<{ tokenId: number, tokenURI: string }[]> => {
    return web3Service.getTokensURI(address);
  };



  const removeAuthorized = async (addressToRemove: string): Promise<boolean> => {
    if (!userAddress) {
      console.error("Admin address is not defined.");
      return false;
    }
    return await removeAuthorizedAddress(userAddress, addressToRemove);
  };

  const depositFunds = async (amount: string): Promise<boolean> => {
    return web3Service.depositFunds(userAddress, amount);
  };

  const ConversionRate = async (newRate: number): Promise<boolean> => {
    return web3Service.setConversionRate(userAddress, newRate);
  };

  const changeRewardPercentage = async (percentage: number): Promise<boolean> => {
    return web3Service.changeRewardPercentage(userAddress, percentage);
  };

  const burnTokenFunction = async (tokenId: number): Promise<boolean> => {
    if (!userAddress) {
      console.error("User address is not defined.");
      return false;
    }
    const success = await web3Service.burnToken(userAddress, tokenId);
    if (success) {
      console.log("Token burned successfully.");
      await updateData(userAddress);
      return true;
    } else {
      console.error("Failed to burn token.");
      return false;
    }
  };



  // Préparez les valeurs à fournir au contexte
  const value = {
    isWalletConnected,
    userAddress,
    userRole,
    isAdmin,
    userTokenBalance,
    userMATICShare,
    contractMATICBalance,
    conversionRate,
    stackReward,
    stackedTokens,
    expectedRedemption,
    availableTokens,
    initiateConnection,
    updateData,
    buy,
    transferTokens,
    stackTokens,
    claimStackedTokens,
    mintTokens,
    getTransactionHash,
    getTotalSupply,
    getTokensURI,
    sellTokens,
    removeAuthorized,
    depositFunds,
    ConversionRate,
    changeRewardPercentage,
    remainingStackingTime,
    burnToken: burnTokenFunction,
    getTokensOfOwner,
    addAuthorized
  };

  return <Web3Context.Provider value={value}>{children}</Web3Context.Provider>;
};

// Hook personnalisé pour utiliser le contexte Web3
export const useWeb3 = () => {
  const context = useContext(Web3Context);
  if (context === undefined) {
    throw new Error('useWeb3 must be used within a Web3Provider');
  }
  return context;
};

